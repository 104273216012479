import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/misc/staffList.module.scss';
import { LangContext } from 'context/LangContext';

const StaffList = ({ members, title = null, memberKeyPrefix = '' }) => {
    const { lang } = useContext(LangContext);

    const getInitials = name => name.split(' ').slice(0, 2).map(n => n.charAt(0));

    return (
        <div className={style.staffList}>
            {title && (
                <h3 className="title-xs bold">{title}</h3>
            )}

            <div className={style.staffList__container}>
                {members.map((member) => (
                    <div key={`${memberKeyPrefix}-${member._id}`} className={style.card}>
                        <div className={style.card__avatar}>
                            {member.image && (
                                <ImageGatsby
                                    {...member.image}
                                    alt={member.name}
                                    title={member.name}
                                />
                            )}

                            {!member.image && (
                                <div className={style.card__initials}>{getInitials(member.name)}</div>
                            )}
                        </div>

                        <div className={style.card__name}>
                            <p className="text-lg bold">{member.name}</p>

                            {member.job && member.job[lang.translationKey] && (
                                <p className="text-md medium">{member.job[lang.translationKey]}</p>
                            )}
                        </div>

                        <div className={style.card__contact}>
                            {member.email && (
                                <a href={`mailto:${member.email}`} target="_blank" rel="noreferrer"
                                   className={`${style.card__email} text-md`}>
                                    {member.email}
                                </a>
                            )}

                            {member.phone && (
                                <a href={`tel:${member.phone}`} target="_blank" rel="noreferrer"
                                   className="text-md">
                                    {member.phone}
                                </a>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StaffList;
