import { useStaticQuery, graphql } from 'gatsby';

const useContactPage = () => {
    const {
        sanityPageContact: pageContact,
    } = useStaticQuery(graphql`
        query {
            sanityPageContact {
                contact {
                    en {
                        _rawContent
                    }
                    se {
                        _rawContent
                    }
                }
                hammarbyStaff {
                    _key
                    members {
                        _id
                        image {
                            ...ImageWithPreview
                        }
                        email
                        name
                        job {
                            en
                            se
                        }
                        phone
                    }
                    title {
                        en
                        se
                    }
                }
            }
        }
    `);

    return pageContact;
};

export default useContactPage;
