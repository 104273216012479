import React, { useContext } from 'react';

import * as style from 'styles/components/contact/contactPage.module.scss';
import { LangContext } from 'context/LangContext';
import useContactPage from 'data/queries/useContactPage';
import StaffList from 'components/misc/StaffList';
import FormattedMessage from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

const ContactPage = () => {
    const { lang } = useContext(LangContext);
    const data = useContactPage();

    return (
        <div className={`${style.container} wrapper`}>
            <div className={style.contact}>
                <h1 className="title-sm-mobile title-md bold">
                    <FormattedMessage id="contact.contactUs" />
                </h1>

                {data.contact && data.contact[lang.translationKey] && (
                    <TextEditorRender content={data.contact[lang.translationKey]._rawContent} />
                )}
            </div>

            <h2 className="title-sm-mobile title-md bold">
                <FormattedMessage id="contact.organisation" />
            </h2>

            {data.hammarbyStaff.length !== 0 &&
                data.hammarbyStaff.map((item) => (
                    <div key={item._key} className={style.staffList}>
                        <StaffList
                            members={item.members}
                            title={item.title[lang.translationKey]}
                            memberKeyPrefix={item._key}
                        />
                    </div>
                ))
            }
        </div>
    );
};

export default ContactPage;
